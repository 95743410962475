<template>
  <section :style="getStyleInDocument">
    <div v-loading="loading" :style="getStyleInDocument" class="main-class">
      <!-- <el-row :gutter="10">
        <el-col :span="isFromTemplate ? 24 : 18"> -->
      <el-select
        v-model="repeatableDataId"
        @change="updateRepeatableData"
        :disabled="isDisable"
        clearable
        :style="`height:${this.form.height - 3}px; width: 100% !important;`"
        filterable
      >
        <el-option
          v-for="(temp, i) in repeatableData"
          :key="i"
          :value="temp.indexKey"
          :label="getLabel(temp, i + 1)"
          >{{ getLabel(temp, i + 1) }}
        </el-option>
      </el-select>
          <el-link v-if="!isFromTemplate" :underline="false" @click="openRepeatableDataModal"
            ><i class="el-icon-circle-plus-outline"></i>
          </el-link>
    </div>
  </section>
</template>
<script>
import { bus } from "../../../main";
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["entityId", "form", "isDisable", "isFromTemplate"],
  data() {
    return {
      loading: false,
      repeatableDataId: "",
      repeatableData: [],
    };
  },
  created() {
    bus.$on("repeatable-data-selected", (data) => {
      if (data && data.key == this.form.key) {
        this.setRepetableData(
          data.repeatableData && data.repeatableData.length
            ? data.repeatableData
            : null,
          data && data.indexKey ? data.indexKey : null
        );
      }
    });
  },
  computed: {
    getStyleInDocument() {
      return `height:${this.form.height}px; width:${
        this.form.width
      }px !important;font-size:${
        this.form.font && this.form.font.size ? this.form.font.size : "16"
      }px !important;font-style: ${
        (this.form && this.form.font && this.form.font.style == 2) ||
        (this.form && this.form.font && this.form.font.style == 3)
          ? "italic"
          : "normal"
      } !important; font-weight:${
        (this.form && this.form.font && this.form.font.style == 1) ||
        (this.form && this.form.font && this.form.font.style == 3)
          ? "bold"
          : "normal"
      } !important; color:${
        this.form && this.form.font && this.form.font.color
          ? this.form.font.color
          : "black"
      } !important ;font-family:${
        this.form && this.form.font && this.form.font.name
          ? this.form.font.name
          : "helvetica"
      } !important; padding: 0px !important;`;
    },
    calculateRepeatableData() {
      let max_value =
        this.form &&
        this.form.repeatable_field &&
        this.form.repeatable_field.max_value
          ? this.form.repeatable_field.max_value
          : 1;
      let result = [];
      for (let i = 0; i < max_value; i++) {
        if (
          this.form &&
          this.form.repeatable_data &&
          this.form.repeatable_data[i]
        ) {
          result.push(this.form.repeatable_data[i]);
        }
        // else {
        //   result.push({
        //     nameKey:
        //       this.form &&
        //       this.form.repeatable_field &&
        //       this.form.repeatable_field &&
        //       this.form.repeatable_field.template_name
        //         ? this.form.repeatable_field.template_name + " " + (i + 1)
        //         : "New data " + (i + 1),
        //     indexKey: "newData:" + (i + 1),
        //   });
        // }
      }
      return result;
    },
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    ...mapGetters("templatesData", ["getRepeatableTemplateIds"]),
  },
  mounted() {
    this.setRepetableData();
  },
  methods: {
    async openRepeatableDataModal() {
      try {
        let max_value =
          this.form &&
          this.form.repeatable_field &&
          this.form.repeatable_field.max_value
            ? this.form.repeatable_field.max_value
            : 1;
        if (max_value <= this.repeatableData.length) {
          this.$notify({
            title: "Warning",
            message: "Max number of records already added. Can't add new data.",
            type: "warning",
          });
          return;
        }
        this.loading = true;
        await Promise.all([
          this.$store.dispatch(
            "companyTemplates/fetchSingleCompanyTemplate",
            this.form.template_id
          ),
          await this.$store.dispatch(
            "templatesData/fetchRepeatableTemplateIds",
            max_value
          ),
        ]);
        if (this.getSingleCompanyTemplate) {
          this.loading = false;
          bus.$emit("open-repeatable-data", {
            field: this.form,
            template_data: this.getSingleCompanyTemplate,
            indexKeys: this.getRepeatableTemplateIds,
          });
        }
      } catch (err) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Error while fetching template",
        });
      }
    },
    getLabel(item, i) {
      let name =
        this.form &&
        this.form.repeatable_field &&
        this.form.repeatable_field.template_name
          ? this.form.repeatable_field.template_name + " " + i
          : "data " + i;
      if (
        item &&
        this.form &&
        this.form.repeatable_field &&
        this.form.repeatable_field.primary_key &&
        item[this.form.repeatable_field.primary_key]
      ) {
        name = item[this.form.repeatable_field.primary_key + "/name"]
          ? item[this.form.repeatable_field.primary_key + "/name"]
          : item[this.form.repeatable_field.primary_key];
      }
      return name;
    },
    checkAndUpdateData() {
      if (
        this.form &&
        this.form.indexKey &&
        this.repeatableData &&
        this.repeatableData.length
      ) {
        let selectedRepeatableData = this.repeatableData.find(
          (e) => e.indexKey == this.form.indexKey
        );
        if (selectedRepeatableData) {
          this.repeatableDataId = this.form.indexKey;
          this.updateRepeatableData();
        }
      }
    },
    updateRepeatableData() {
      if (this.repeatableData && this.repeatableData.length) {
        let selectedRepeatableData = this.repeatableData.find(
          (e) => e.indexKey == this.repeatableDataId
        );
        let label = this.getLabel(selectedRepeatableData);
        if (selectedRepeatableData) {
          bus.$emit("repeatableData-updated", {
            repeatableDataId: this.repeatableDataId,
            field: this.form,
            data: selectedRepeatableData,
            value: label,
          });
        } else {
          bus.$emit("repeatableData-updated", {
            repeatableDataId: this.repeatableDataId,
            field: this.form,
            data: [],
            value: label,
          });
        }
      }
    },
    setRepetableData(data, indexKey) {
      this.repeatableData = [];
      if (data && data.length) {
        this.repeatableData = data;
      } else {
        this.repeatableData = this.calculateRepeatableData;
      }
      this.repeatableDataId = indexKey ? indexKey : "";
      if (indexKey) {
        this.form.indexKey = indexKey;
      }
      this.checkAndUpdateData();
    },
  },
  beforeDestroy() {
    bus.$off("repeatable-data-selected", () => {
      console.log("destroyed");
      // this.fetchEntityData();
    });
    // bus.$off("entityData-updated");
  },
};
</script>
<style lang="scss" scoped>
.main-class {
  display: flex !important;
}
/* .custom-icon {
  font-size: 5px;
} */
</style>
